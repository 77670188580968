import React from "react";

const NotFoundPage = () => {
  return (
    <div className="lg:h-[75vh] pt-20 pb-10 w-full flex flex-col justify-center items-center bg-gray-900 text-ML_white-500 lg:p-5 lg:flex-row">
      PAGE NOT FOUND
    </div>
  );
};

export default NotFoundPage;
