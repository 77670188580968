import React from "react";
import Cookies from "../__sections/Cookies/Cookies";

const CookiesPage = () => {
  return (
    <>
      <Cookies />
    </>
  );
};

export default CookiesPage;
