import React from "react";

const University = () => {
  return (
    <div className="lg:h-[75vh] pt-20 pb-10 w-full flex flex-col justify-center items-center bg-gray-900 text-ML_white-500 lg:p-5 lg:flex-row">
      <div className="lg:text-xl lg:w-[30%] p-5 flex flex-col justify-start items-center">
        <div className="lg:text-5xl pb-5">Dyżury:</div>
        <ul className="p-5 flex flex-col justify-start items-center">
          <li className="font-extrabold">7.03 / 21.03</li>
          <li>4.04 / 18.04</li>
          <li>9.05 / 23.05</li>
          <li>6.06 / 21.06</li>
          <li>7.03 / 21.03</li>
        </ul>
        <div>
          W godzinach 12:00-14:00 stacjonarnie (gabinet 405) oraz na platformie
          MS TEAMS
        </div>
      </div>
    </div>
  );
};

export default University;
