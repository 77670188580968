import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div
      id="footer"
      className="lg:h-[15vh] flex bg-gray-900 text-ML_white-500 2xl:items-center pb-10 lg:pb-0"
    >
      <div className="h-full flex flex-col justify-center items-center w-full lg:flex-wrap lg:flex-row lg:p-5">
        <div className="pb-10 w-[100%] lg:w-[20%] lg:pb-0">
          <a href="https://www.marcinlubinski.com">
            <div className="bg-contain bg-no-repeat bg-center flex items-center bg-ML_logo1 w-full h-28 lg:h-20 2xl:h-28 sm:text-3xl lg:text-4xl hover:opacity-70 transition duration-500 ease-in-out"></div>
          </a>
        </div>
        <div className="w-[80%] lg:w-[80%] flex flex-col lg:flex-row lg:flex-wrap justify-start items-center gap-5">
          <div className="w-full lg:w-[70%] flex flex-col lg:flex-row lg:flex-wrap xl:gap-5">
            <div className="w-full lg:w-auto flex justify-start items-center cursor-pointer hover:text-ML_white-700 transition duration-500 ease-in-out">
              <i className="pl-2 pr-2 fa-solid fa-phone 2xl:text-3xl"></i>
              <a
                href="tel:+48730819930"
                className="w-[80] text-sm xs:text-lg 2xl:text-3xl no-underline"
              >
                +48 730 819 930
              </a>
            </div>
            <div className="hidden w-full lg:w-auto flex justify-start items-center cursor-pointer hover:text-ML_white-700 transition duration-500 ease-in-out">
              <i className="pl-2 pr-2 fa-regular fa-envelope 2xl:text-3xl"></i>
              <div className="w-[80%] text-sm xs:text-lg 2xl:text-3xl">
                kontakt@marcinlubinski.com
              </div>
            </div>
            <div className="w-full lg:w-auto flex justify-start items-center cursor-pointer hover:text-ML_white-700 transition duration-500 ease-in-out">
              <i className="pl-2 pr-2 fa-regular fa-envelope 2xl:text-3xl"></i>
              <a
                href="mailto:marcin.lubinski@up.krakow.pl"
                className="w-[80%] text-sm xs:text-lg 2xl:text-3xl no-underline"
              >
                marcin.lubinski@up.krakow.pl
              </a>
            </div>
            <div className="w-full lg:w-auto flex justify-start items-center">
              <a
                href="https://www.linkedin.com/in/marcin-%C5%82-92ba63225"
                target="_blank"
                rel="noreferrer"
                className="xs:hover:text-ML_blue-600 block text-sm xs:text-lg 2xl:text-3xl transition duration-500 ease-in-out"
              >
                <i className="pl-2 pr-2 fa-brands fa-linkedin 2xl:text-3xl"></i>
                LinkedIn
              </a>
            </div>
          </div>
          <div className="w-full lg:w-[30%] flex flex-col lg:flex-row lg:flex-wrap xl:gap-5">
            <div className="w-full lg:w-auto flex justify-start items-center cursor-pointer hover:text-ML_white-700 transition duration-500 ease-in-out">
              <i className="pl-2 pr-2 fa-solid fa-note-sticky 2xl:text-3xl"></i>
              <Link to="/" className="w-[80%] text-sm xs:text-lg 2xl:text-3xl">
                Regulaminy
              </Link>
            </div>
            <div className="w-full lg:w-auto flex justify-start items-center cursor-pointer hover:text-ML_white-700 transition duration-500 ease-in-out">
              <i className="pl-2 pr-2 fa-solid fa-cookie-bite 2xl:text-3xl"></i>
              <Link
                to="/cookies"
                className="w-[80%] text-sm xs:text-lg 2xl:text-3xl"
              >
                Cookies
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
