export const pageData = [
  {
    page: "about-me",
    sectionData: [
      {
        header: "O Mnie",
        data: [
          "Jestem absolwentem Uniwersytetu Zielonogórskiego na kierunku Pedagogika oraz Uniwersytetu Pedagogicznego im. Komisji Edukacji Narodowej na kierunku Mediacje i Negocjacje. ",
          "W ciągu kilkunastu lat aktywności zawodowej poznałem niemal każdą stronę komunikacji z klientem. Pracowałem w branżach sprzedażowych, ubezpieczeń, w licznych usługach i hotelarstwie. Zdobyłem dzięki temu cenne doświadczenie związane z odpowiedzialnością za siebie, markę oraz utrzymanie dobrych relacji z klientem.",
        ],
      },
      {
        header: "Praca akademicka",
        data: [
          "Od zawsze pociągała mnie nowa wiedza i rozwój osobisty. W związku z tym starałem się spełniać nie tylko praktycznie i zawodowo, ale także akademicko. Uważam, że wiedza i doświadczenie muszą iść ze sobą w parze i nawet najlepszy praktyk nie jest w stanie dobrze uczyć, jeśli nie ma za sobą zaplecza naukowego. ",
          "W roku 2019 dostałem się do Szkoły Doktorskiej Uniwersytetu Pedagogicznego. Rok później została mi zaoferowana praca w Instytucie Filozofii i Socjologii UP jako wykładowca przedmiotów związanych z mediacjami i negocjacjami. W roku 2022 przeniosłem się do nowopowstałego Instytutu Dziennikarstwa i Stosunków Międzynarodowych gdzie zatrudniono mnie na stanowisko Asystenta. Została mi także powierzona rola Opiekuna Naukowego Koła Mediacji i Negocjacji. ",
          "Od roku 2017 współtworzę coroczną konferencję naukową „Międzynarodowy tydzień mediacji i negocjacji na Uniwersytecie Pedagogicznym”, gdzie popularyzuję wiedzę o ADR (ang. Alternative Dispute Resolution – alternatywne [do sądów] rozwiązywanie sporów), komunikacji oraz zastosowaniu negocjacji w życiu codziennym. ",
          "Od roku 2022 pełnię także funkcję Sekretarza oraz Redaktora w czasopiśmie naukowym Edukacja Etyczna (Lista ERICH PLUS 40 pkt.)",
        ],
      },
      {
        header: "Szkolenia",
        data: [
          "Poza pracą akademicką prowadzę (link do zakładki) szkolenia związane z tematyką: ",
          [
            "Mediacji",
            "Negocjacji (biznesowe, międzykulturowe i międzynarodowe) ",
            "Budowy zespołu negocjacyjnego oraz biznesowego",
            "Utrzymywania relacji z klientem",
            "Zarządzania wizerunkiem firmy",
            "Zarządzania czasem pracy",
            "Pozyskiwaniem pracowników",
            "Lean Management, Agile, Kanban",
            "Komunikacji (aspekty werbalne, niewerbalne i lingwistyczne) ",
          ],
        ],
      },
      {
        header: "Ukończone kursy",
        data: [
          [
            "Negocjacje biznesowe",
            "Negocjacje międzynarodowe ",
            "Rozwiązywanie sporów w szkole",
            "Animacja czasu wolnego",
          ],
        ],
      },
      {
        header: "Hobby",
        data: [
          "Moje zainteresowania obejmują przede wszystkim zdobywanie nowej wiedzy. Informacje zdobyte teoretycznie (książkowo) lubię uzupełniać o wiedzę praktyczną – w związku z tym często podróżuję z plecakiem i namiotem. Uważam, że najlepszym sposobem na poznanie i zrozumienie drugiego człowieka jest poznanie jego kultury, języka i miejsca z którego pochodzi. W ten sposób jestem w stanie przekazać najrzetelniejszą wiedzę na temat komunikacji międzykulturowej i budowania relacji biznesowych z przedstawicielami innych kultur. ",
          "Moim największym sukcesem podróżniczym była samotna piesza wędrówka z plecakiem i namiotem z Polski do Hiszpanii. Podczas kilkunastu miesięcy podróży poznałem samego siebie, oraz nauczyłem się wielu rzeczy o życiu – podejmowałem także prace w różnych kulturach i środowiskach, zawierałem przyjaźni na całe życie. ",
          "Poza podróżami w nieznane lubię wypocząć w górach. Jakimś cudem udało mi się odwiedzić niemal wszystkie masywy w Europie, dotarłem w góry Atlas i Himalaje, ale nie udało mi się odwiedzić polskich Tatr – zdecydowanie ukochałem sobie jednak Bieszczady w których jestem niemal co roku",
        ],
      },
    ],
  },
  {
    page: "courses",
    sectionData: [
      {
        header: "Zastosowanie ADR w biznesie",
        data: [
          "ADR czyli Alternative Dispute Resolution – alternatywne [do sądów] rozwiązywanie sporów – jest systemem rozwiązywania konfliktów i problemów związanych z komunikacją na polach, która są przyjaźniejsze dla klienta oraz naszej firmy. Postępowania sądowe są drogie, ciągną się latami i nie zawsze idą po naszej myśli – często zostawiając złą opinię – w związku z tym rozwiązania biznesowe wymagają nowego, lepszego podejścia do tematu. ",
          "Na szkoleniu z ADR poznamy podstawy mediacji, negocjacji, arbitrażu. Dowiemy się jak inkorporować je do naszej działalności i w jaki sposób najefektywniej je poprowadzić. ",
          "ADR stanowi przyszłość rozwiązywania sporów i konfliktów, generuje także najmniejsze możliwe koszty i odbywa się w najkrótszym możliwym czasie przy pełnym wsparciu ze strony ustawodawstwa i sądownictwa. ",
        ],
      },
      {
        header: "Negocjacje międzykulturowe i międzynarodowe",
        data: [
          "XXI wiek jest epoką globalizacji. Nie raz przychodzi nam zdobywanie klientów, czy kontrahentów z drugiego końca globu. Jednak jak najlepiej się z nimi porozumieć? Co ich przyciąga, a co odpycha? W jaki sposób okazać im zainteresowanie, by je odwzajemnili? ",
          "Oferuję odpowiedzi na te pytania w czasie szkolenia z negocjacji międzynarodowych i międzykulturowych. ",
          "Na szkoleniu zapoznacie się Państwo z kulturowymi modelami negocjacji, dowiecie się o etyce wskazanych kultur, ich filozofii biznesu, oraz sposobach na efektywną komunikację i prowadzenie rozmów biznesowych. ",
          "Dowiecie się także jak rozkładać spotkania w czasie, jak zbudować odpowiedni zespół negocjacyjny i jak prowadzić cały proces od początku do końca. Dowiecie się także Państwo w jaki sposób utrzymać długotrwałą i owocną współpracę także po zakończeniu negocjacji.",
        ],
      },
      {
        header: "Zarządzanie wizerunkiem firmy",
        data: [
          "W wielu krajach Azji twarz firmy jest twarzą pracownika i odwrotnie. Kolektywnie wszyscy pracownicy starają się dać z siebie jak najwięcej i sprawić, że opinia o przedsiębiorstwie była nienaganna. ",
          "Podobne idee można wprowadzać także w Polsce, zwiększając zaangażowanie pracowników, przyciągając inwestorów oraz sprawiać, że nasza marka stanie się świetnie rozpoznawalna nie tylko w postaci produktu, ale także rzetelności i jakości wykonanych zadań. ",
          "Szkolenie przeznaczone jest szczególnie dla kadry zarządzającej i menagerów. Podczas jego trwania poznacie Państwo najważniejsze kierunki rozwoju marki, jej wizerunku w przedsiębiorstwie jak i poza nim, a także tego w jaki sposób może efektywnie podnieść społeczną odpowiedzialność biznesu oraz zaangażować pracowników w promowanie firmy nie tylko poprzez efektywną pracę, ale także inne działania. ",
        ],
      },
      {
        header: "Zarządzanie zespołem (Lean, Agile, Kanban, Kaizen)",
        data: [
          "Współcześnie większość przedsiębiorców dąży do minimalizacji kosztów, poprawy współpracy z zespołem i klientem, likwidacji nieefektywności i jednoczesnej maksymalizacji wydajności. Nasze szkolenie pomoże Państwu osiągnąć te cele poprzez optymalizację procesów związanych z planowaniem, pracą i produkcją. Dowiedzą się Państwo, jak rozpoznawać i usuwać przeszkody i zagrożenia, a także jak dokładniej przewidywać skutki działań zespołu i firmy.",
          "Na szkoleniu z ADR poznamy podstawy mediacji, negocjacji, arbitrażu. Dowiemy się jak inkorporować je do naszej działalności i w jaki sposób najefektywniej je poprowadzić. ",
          "Na kursie nauczycie się Państwo jak zastosować zaawansowane techniki zarządzania zespołem, które pomogą w efektywnym zarządzaniu projektami oraz poprawie wydajności zespołu. Dzięki wykorzystaniu narzędzi Lean, Agile, Kanban i Kaizen, nauczcie się Państwo, jak zoptymalizować proces pracy i zwiększyć wydajność.",
        ],
      },
      {
        header: "Komunikacja w biznesie",
        data: [
          "Komunikacja w biznesie to proces wymiany informacji między pracownikami i przedsiębiorcą, lub kontrahentem w celu realizacji celów biznesowych. Jest to jedna z najistotniejszych umiejętności miękkich we współczesnym świecie biznesu. Bez dobrej komunikacji, praca w zespole i osiąganie celów biznesowych może stać się trudne, a nawet niemożliwe. ",
          "Szkolenie oferuje Państwu zapoznanie się z najważniejszymi elementami komunikacji werbalnej, niewerbalnej, oraz socjo-lingwistycznej. ",
          "Podczas szkolenia skorzystacie Państwo z praktycznych ćwiczeń, które pomogą zastosować wiedzę w praktyce i rozwinąć umiejętności. Nauczycie się Państwo również, jak skutecznie wykorzystać różne narzędzia komunikacyjne, takie jak prezentacje, e-maile, rozmowy telefoniczne i spotkania biznesowe, a także w jaki sposób nawiązać kontakt czy rozmowę z przedstawicielami innych narodowości, czy kultur. ",
        ],
      },
    ],
  },
  {
    page: "science-club",
    sectionData: [
      {
        header: "Spotkania Koła Naukowego Mediatorów i Negocjatorów",
        data: [
          "Spotkania Koła Naukowego Mediatorów i Negocjatorów odbywać będą się w co drugą środę między 14:00-16:00 w sali 24 (Główny budynek uczelni)",
        ],
      },
      {
        header: "Organizacja 2023/2024",
        data: [
          "Targi pracy w Poznaniu - 17.04.2024",
          "Dzień Otwarty UKEN - 19.04.2024",
          "II Konferencja Kół Naukowych - 16-17.05.2024",
        ],
      },
    ],
  },
  { page: "university" },
];
