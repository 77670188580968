import React from "react";

const Cookies = () => {
  return (
    <div className="lg:h-[75vh] pt-20 pb-10 pl-20 pr-20 w-full flex flex-col justify-center bg-gray-900 text-ML_white-500">
      <p>
        Polityka dotycząca cookies W celu świadczenia usług na najwyższym
        poziomie stosujemy pliki cookies. Korzystanie z naszej witryny oznacza,
        że będą one zamieszczane w Państwa urządzeniu. W każdym momencie można
        dokonać zmiany ustawień Państwa przeglądarki.
      </p>
      <p>
        Należy mieć na uwadze, że zmiany ustawień tych plików polegające na
        ograniczeniu ich stosowania mogą wpłynąć na niektóre funkcjonalności
        dostępne na stronach internetowych je stosujących np. uniemożliwiając
        zalogowanie się na konto pocztowe. Brak zmiany tych ustawień oznacza
        akceptację dla stosowania plików typu cookies. Poniżej wskazujemy sposób
        zmiany ustawień cookies w najbardziej popularnych wyszukiwarkach:
      </p>
      <p>
        Google Chrome Menu > Ustawienia > Pokaż ustawienia zaawansowane >
        Prywatność > Ustawienia treści > Pliki cookies – należy wybrać
        odpowiednią opcję.{" "}
      </p>
      <p>
        Internet Explorer Menu > Narzędzia > Opcje internetowe > Prywatność –
        należy wybrać odpowiednią opcję.{" "}
      </p>
      <p>
        Mozilla Firefox Menu > Opcje > Prywatność > Historia – należy wybrać
        odpowiednią opcję.{" "}
      </p>
      <p>
        Opera Menu > Preferencje > Zaawansowane > Ciasteczka – należy wybrać
        odpowiednią opcję.{" "}
      </p>
      <p>
        Safari Menu > Preferencje > Prywatność > Pliki cookies – należy wybrać
        odpowiednią opcję.
      </p>
    </div>
  );
};

export default Cookies;
