import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="flex flex-row z-[3] sticky top-0 w-full text-sm sm:text-lg 2xl:text-3xl md-text-2xl h-[10vh] p-5 text-ML_white-500 bg-gray-900 justify-between items-center">
      <div className="flex h-full lg:gap-5">
        <a href="https://www.marcinlubinski.com">
          <div className="h-full w-32 md:w-48 2xl:w-80 bg-contain bg-no-repeat bg-center flex items-center p-2 bg-ML_logo3 hover:opacity-70 transition duration-500 ease-in-out"></div>
        </a>
        <Link
          to="/"
          className="h-full xs:flex items-center text-center p-2 hover:text-ML_white-700 transition duration-500 ease-in-out"
        >
          Strona Główna
        </Link>
        <Link
          to="/courses"
          className="hidden h-full xs:flex items-center text-center p-2 hover:text-ML_white-700 transition duration-500 ease-in-out"
        >
          Oferta szkoleń
        </Link>
        {/* <Link
          to="/student"
          className="hidden h-full xs:flex items-center text-center p-2 hover:text-ML_white-700 transition duration-500 ease-in-out"
        >
          Dla Studenta
        </Link> */}
        <Link
          to="/science-club"
          className="hidden h-full xs:flex items-center text-center p-2 hover:text-ML_white-700 transition duration-500 ease-in-out"
        >
          Koło naukowe
        </Link>
        <Link
          to="/university"
          className="hidden h-full xs:flex items-center text-center p-2 hover:text-ML_white-700 transition duration-500 ease-in-out"
        >
          Uniwersytet
        </Link>
      </div>
      <div className="flex h-full">
        <div className="hidden h-full flex items-center p-2">Logowanie</div>
        <div className="hidden h-full flex items-center p-2">Rejestracja</div>
      </div>
    </div>
  );
};

export default Navbar;
