import React, { useState } from "react";
import { pageData } from "../../constants/data.js";

const AboutMe = () => {
  const [arrowState, setArrowState] = useState(0);
  return (
    <div className="lg:h-[75vh] pt-20 pb-10 w-full flex flex-col justify-center items-center bg-gray-900 text-ML_white-500 lg:p-5 lg:flex-row">
      <div className="lg:w-[30%] p-5 flex flex-col justify-start items-center">
        {pageData[0]?.sectionData.map((item, i) => (
          <div
            key={i}
            onClick={() => setArrowState(i)}
            className={`text-sm xs:text-lg 2xl:text-3xl w-full hover:bg-gray-700 ${
              arrowState === i ? "bg-gray-700" : ""
            } p-2 flex justify-between items-center transition duration-500 ease-in-out cursor-pointer`}
          >
            {item?.header}
            {arrowState === i ? (
              <i
                className={`fa-solid fa-chevron-right ${
                  arrowState === i ? "slide-in" : ""
                }`}
              ></i>
            ) : null}
          </div>
        ))}
      </div>
      <div className="lg:w-[70%] lg:h-[100%] text-sm xs:text-lg 2xl:text-3xl p-5">
        <div className="lg:h-[100%] flex flex-col justify-center">
          {pageData[0]?.sectionData[arrowState]?.data.map((p, i) =>
            Array.isArray(p) ? (
              <div key={i}>
                {p.map((item, j) => (
                  <p className="p-1 ml-6 list-item" key={`${i}-${j}`}>
                    {item}
                  </p>
                ))}
              </div>
            ) : (
              <p className="p-4" key={i}>
                {p}
              </p>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
