import React from "react";
import Introduction from "../__sections/Home/Introduction";

const HomePage = () => {
  return (
    <>
      <Introduction />
    </>
  );
};

export default HomePage;
