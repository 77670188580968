import "./App.css";
import { Routes, Route } from "react-router-dom";
import {
  HomePage,
  ScienceClubPage,
  NotFoundPage,
  NewsPage,
  CoursesPage,
  AboutMePage,
  UniversityPage,
  CookiesPage,
} from "./__pages/_index.jsx";
import { Navbar, Footer } from "./__components/_index.jsx";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />

        <Route path="/about-me" element={<AboutMePage />} />
        <Route path="/courses" element={<CoursesPage />} />
        <Route path="/science-club" element={<ScienceClubPage />} />
        <Route path="/university" element={<UniversityPage />} />

        <Route path="/cookies" element={<CookiesPage />} />

        <Route path="/news" element={<NewsPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
