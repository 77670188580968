import React from "react";
import AboutMe from "../__sections/AboutMe/AboutMe";

const AboutMePage = () => {
  return (
    <>
      <AboutMe />
    </>
  );
};

export default AboutMePage;
