import React from "react";
import { Link } from "react-router-dom";

const Introduction = () => {
  return (
    <div className="lg:h-[75vh] pt-20 pb-10 w-full flex flex-col justify-center items-center bg-gray-900 text-ML_white-500 lg:p-5 lg:flex-row">
      <div className="w-full xl:w-[45%] self-center flex justify-start z-[2] flex-col">
        <div className="text-4xl lg:text-4xl px-5 pb-5 2xl:text-6xl">
          <p className="text-gray-400">Nazywam się</p>
          <p className="text-5xl lg:text-7xl">Marcin Łubiński</p>
        </div>
        <hr className="w-1/2 border ml-5 mr-5 xs:w-1/4" />
        <div className="text-lg lg:text-2xl p-5 pb-10 2xl:text-3xl xl:w-4/5">
          Jestem wykładowcą akademickim, trenerem, oraz pedagogiem. Na co dzień
          zajmuję się kształceniem studentów oraz klientów biznesowych z
          tematyki komunikacji, negocjacji, budowania zespołu biznesowego, oraz
          wizerunku firmy, a także zarządzania relacjami z klientem. Jeśli
          jesteś zainteresowany współpracą, lub szukasz konkretnych informacji –
          zapraszam!
        </div>
      </div>
      <div className="p-2 h-[60rem] lg:h-[40rem] w-full xl:w-[45%]">
        <div className="xl:m-[2px] h-1/2 flex flex-col xl:flex-row">
          <Link
            to="/about-me"
            className="m-[1px] bg-ML_about_me bg-no-repeat bg-cover h-full xl:w-1/2 text-3xl lg:text-4xl hover:opacity-70 font-bold flex justify-center items-center transition duration-500 ease-in-out"
          >
            <p className="p-2 border-gray-400 text-white opacity-50 bg-gray-900">
              O mnie
            </p>
          </Link>
          <Link
            to="/courses"
            className="m-[1px] bg-ML_courses bg-no-repeat bg-cover h-full xl:w-1/2 text-3xl lg:text-4xl hover:opacity-70 font-bold flex justify-center items-center transition duration-500 ease-in-out"
          >
            <p className="p-2 border-gray-400 text-white opacity-50 bg-gray-900">
              Szkolenia
            </p>
          </Link>
        </div>
        <div className="xl:m-[2px] h-1/2 flex flex-col xl:flex-row">
          <Link
            to="/university"
            className="m-[1px] bg-ML_university bg-no-repeat bg-cover bg-center h-full xl:w-1/2 text-3xl hover:opacity-70 lg:text-4xl font-bold flex justify-center items-center transition duration-500 ease-in-out"
          >
            <p className="p-2 border-gray-400 text-white opacity-50 bg-gray-900">
              Uniwersytet
            </p>
          </Link>
          <Link
            to="/science-club"
            className="m-[1px] bg-ML_science_club bg-no-repeat bg-cover h-full xl:w-1/2 text-3xl lg:text-4xl hover:opacity-70 font-bold flex justify-center items-center transition duration-500 ease-in-out"
          >
            <p className="p-2 border-gray-400 text-white opacity-50 bg-gray-900">
              Koło naukowe
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
